import { useColorMode } from '@chakra-ui/react'
import Head from 'next/head'
import React from 'react'
import { env } from 'env.client'

type SeoProps = {
  title?: string
  description?: string
  keywords?: string
  url?: string
}

const APP_URL =
  env.NEXT_PUBLIC_NETWORK === 'mainnet'
    ? 'https://bridge.zircuit.com'
    : `https://bridge.${env.NEXT_PUBLIC_NETWORK}.zircuit.com`

export const Seo = ({
  title = 'Zircuit Bridge',
  description = 'Experience the safest chain for DeFi and staking. Developers get powerful features; users get peace of mind.',
  keywords = 'Zircuit, Bridge, Blockchain, Cross-chain, Zircuit Network, Ethereum Bridge, Binance Wallet, Crypto Transfer, Blockchain Bridge, DeFi, Cryptocurrency, Fast Transactions, Secure Transactions, Reduced Fees, Web3',
  url = APP_URL,
}: SeoProps) => {
  const { colorMode } = useColorMode()

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" type="image/svg" href={`/favicon_${colorMode}.svg`} />

      {/* Open Graph tags for Facebook, Instagram, and Threads */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={`${APP_URL}/assets/meta-img.webp`} />
      <meta property="og:image:alt" content="floating island with zircuit logo above it" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={`${APP_URL}/assets/meta-img.webp`} />
      <meta name="twitter:image:alt" content="floating island with zircuit logo above it" />
    </Head>
  )
}
