import React, { useMemo } from 'react'
import { DEFAULT_VALUE } from 'constants/common'
import { ETH } from 'constants/network'
import type { Asset, Erc20TokenAsset } from 'hooks/useAssets'
import { useAssets } from 'hooks/useAssets'
import type { EthAsset } from 'types/network'

const AssetContext = React.createContext<{
  selectedAsset: Asset
  setSelectedAsset: React.Dispatch<React.SetStateAction<Asset>>
  ethBalance: string
  selectedAssetBalance: string
  assets: Asset[]
  assetsWithBalance: ReturnType<typeof useAssets>['assetsWithBalance']
  addManualAsset: (asset: Erc20TokenAsset) => Promise<void>
  areAssetsWithBalanceLoading: boolean
  refetchAssetsWithBalance: ReturnType<typeof useAssets>['refetchAssetsWithBalance']
}>({
  selectedAsset: ETH,
  setSelectedAsset: () => {},
  ethBalance: DEFAULT_VALUE,
  selectedAssetBalance: DEFAULT_VALUE,
  addManualAsset: async () => {},
  assets: [ETH],
  assetsWithBalance: [
    {
      ...ETH,
      value: DEFAULT_VALUE,
    },
  ],
  areAssetsWithBalanceLoading: true,
  refetchAssetsWithBalance: () => Promise.resolve(),
})

const useAssetContext = () => React.useContext(AssetContext)

type AssetProviderProps = {
  children: React.ReactNode
}

const AssetProvider = ({ children }: AssetProviderProps) => {
  const [selectedAsset, setSelectedAsset] = React.useState<Erc20TokenAsset | EthAsset>(ETH)
  const {
    assets,
    addManualAsset,
    assetsWithBalance,
    areAssetsWithBalanceLoading,
    refetchAssetsWithBalance,
  } = useAssets()

  const contextValues = useMemo(
    () => ({
      selectedAsset,
      setSelectedAsset,
      ethBalance:
        assetsWithBalance.find(({ symbol }) => symbol === ETH.symbol)?.value ?? DEFAULT_VALUE,
      selectedAssetBalance:
        assetsWithBalance.find(({ symbol }) => symbol === selectedAsset?.symbol)?.value ??
        DEFAULT_VALUE,
      assets,
      assetsWithBalance,
      addManualAsset,
      areAssetsWithBalanceLoading,
      refetchAssetsWithBalance,
    }),
    [
      selectedAsset,
      assetsWithBalance,
      assets,
      addManualAsset,
      areAssetsWithBalanceLoading,
      refetchAssetsWithBalance,
    ],
  )

  return <AssetContext.Provider value={contextValues}>{children}</AssetContext.Provider>
}

export { AssetProvider, useAssetContext }
